import React from 'react';
import { Grid } from '@mui/material';

function OneColumn({ children }) {
    const columnContent = React.Children.toArray(children);

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={6} xl={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {columnContent[0]}
            </Grid>
        </Grid>
    );
};

export default OneColumn;
