import React from "react";

export const DEFAULT_THEME = 'light';

export function getDesignPalette (mode) {
    return {
        palette: {
            mode,
            ...(mode === DEFAULT_THEME
                ? {
                    // palette values for light mode
                    primary: {
                        main: '#83b420'
                    },
                    secondary: {
                        main: '#800020'
                    },
                }
                : {
                    // palette values for dark mode
                    primary: {
                        main: '#82c214',
                    },
                    secondary: {
                        main: '#f13131',
                    },
                }),
        },
        typography: {
            fontFamily: [
                'Montserrat',
                'sans-serif',
            ].join(','),
        },
    }
};

export const ThemeContext = React.createContext({
    theme: DEFAULT_THEME,
    toggleTheme: () => {},
});