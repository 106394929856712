import './App.css';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ThemeContext } from '@emotion/react';
import { createTheme } from '@mui/material/styles';
import { DEFAULT_THEME, getDesignPalette } from './services/theme';
import { AppRouter } from './services/router';

function App() {
  const [theme, setTheme] = React.useState(DEFAULT_THEME);
  const toggleTheme = () => {
    console.log('Theme:', theme);
    setTheme(theme === DEFAULT_THEME ? 'dark' : DEFAULT_THEME);
  }
  return (
      <ThemeContext.Provider value={
        {
          theme,
          toggleTheme
        }
      }>
        <ThemeProvider theme={createTheme(getDesignPalette(theme))}>
          <CssBaseline />
          <RouterProvider router={AppRouter} />
        </ThemeProvider>
      </ThemeContext.Provider>
  );
}


export default App;
