import { useTheme } from "@emotion/react";
import { Container, Grid, Typography } from "@mui/material";
import OneColumn from "../core/OneColumn";

export default function Home() {
    const theme = useTheme();

    const marginValues = {
        xs: theme.spacing(6),
        sm: theme.spacing(8),
        md: theme.spacing(10),
        lg: theme.spacing(10),
        xl: theme.spacing(10),
    };


    return (
        <Container sx={{ marginY: marginValues }} >
            <OneColumn>
                <div sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
                    <img src='/FerVic.jpeg'
                    width={'100%'}
                    />
                </div>
            </OneColumn>
        </Container>
    )
}
