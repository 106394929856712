import React from "react";
import { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/home/Home";
import NotFound from "../pages/notfound/NotFount";
import Contact from "../pages/contact/Contact";
import AppLayout from "../layouts/AppLayout";
import Servicios from "../pages/servicios/Servicios";
import Historia from "../pages/historia/Historia";




export const RoutesPath = {
  HOME: '/',
  CONTACT: '/contact',
  SERVICIOS: '/servicios',
  HISTORIA: '/historia'
}

export const AppRouter = createBrowserRouter([
  {
    path: RoutesPath.HOME,
    element: <Suspense><AppLayout behaviour='white' /></Suspense>,
    children: [
      {
        index: true,
        element: <Home />
      },
    ]
  },
  {
    path: RoutesPath.CONTACT,
    element: <Suspense><AppLayout behaviour='default' /></Suspense>,
    children: [
      {
        index: true,
        element: <Contact />
      },
    ]
  },
  {
    path: RoutesPath.SERVICIOS,
    element: <Suspense><AppLayout behaviour='default' /></Suspense>,
    children: [
      {
        index: true,
        element: <Servicios />
      },
    ]
  },
  {
    path: RoutesPath.HISTORIA,
    element: <Suspense><AppLayout behaviour='default' /></Suspense>,
    children: [
      {
        index: true,
        element: <Historia />
      },
    ]
  },
  {
    path: '*',
    element: <NotFound />
  }
])

console.log('AppRouter: ', AppRouter);