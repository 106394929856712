import { useTheme } from "@emotion/react";
import { Container, Grid } from "@mui/material";
import "./contact.css"

export default function ContactForm() {

    const theme = useTheme();

    const marginValues = {
        xs: theme.spacing(6),
        sm: theme.spacing(8),
        md: theme.spacing(10),
        lg: theme.spacing(10),
        xl: theme.spacing(10),
    };
    return (
        <Container sx={{ marginY: marginValues }} >
            <Grid textAlign={"center"} >
                <form className="formulario">
                    <input
                        type="text"
                        placeholder="Nombre"
                    />
                    <input
                        type="text"
                        placeholder="Modelo"
                    />
                    <input
                        type="number"
                        placeholder="Teléfono"
                    />
                    <textarea
                        rows="5"
                        cols="50" />
                    <input
                        type="submit"
                        value="Enviar comentario" />
                </form>
            </Grid>
        </Container>
    )
}