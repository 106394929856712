import { useTheme } from "@emotion/react";
import { Container, Grid, Typography } from "@mui/material";
import ContactForm from "./ContactForm";
export default function Contact() {

    const theme = useTheme();

    const marginValues = {
        xs: theme.spacing(6),
        sm: theme.spacing(8),
        md: theme.spacing(10),
        lg: theme.spacing(10),
        xl: theme.spacing(10),
    };
    return (
        <Container sx={{ marginY: marginValues }}>
            <Typography textAlign='center' variant="h3">Contacto</Typography>
            <Grid textAlign={"center"}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2985.6969113475516!2d2.4029246772966646!3d41.55415928563223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4b5d66f5f0117%3A0x380b009053d2cb47!2sFERVIC%20AUTOMOCI%C3%93!5e0!3m2!1ses!2ses!4v1705905433440!5m2!1ses!2ses"
                    width={600}
                    height={450}
                    style={{ border: 0, width:'100% '}}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                    
                >
                </iframe>
            </Grid>
            <ContactForm />
        </Container>
    )
}