import { Outlet } from "react-router-dom";
import NavigationBar from "../components/core/NavigationBar";


export default function AppLayout() {
    return (
        <div>
            <NavigationBar />
            <Outlet />
        </div>
    )
}